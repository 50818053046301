import React from "react";
import { Layout } from "../components/layout";


const RendezVousPage = () => {
  return (
    <Layout solidHeader pageTitle={"Église Parole de vie | Rendez-vous"}>
      <div className="w-full h-screen flex justify-center pt-32 place-self-center">
        <iframe
          src="https://calendly.com/d/y2s-487-96h?primary_color=4b4a4a"
          name="donorbox"
          allowpaymentrequest=""
          title="Prendre un rendez-vous"
          seamless="seamless"
          frameborder="0"
          scrolling="no"
          width="100%"
        ></iframe>
      </div>
    </Layout>
  );
};

export default RendezVousPage;